import React, { useState, useEffect, useRef, memo } from "react"
// import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { window } from "browser-monads-ts"
// import Modal from "../utils/Modal"
import lottie from "lottie-web"

// import useWindowSize from "../utils/useGatsbyWindowSize"

import { StaticImage } from "gatsby-plugin-image"

import animationLogo from "../../content/assets/lottie/logo_white.json"

function HeroHome() {
  // * WINDOW SIZE
  const [isMediumWidth, setIsMobile] = useState(window.innerWidth <= 768 ? true : false)
  const [isShortHeight, setIsMobileWide] = useState(window.innerHeight <= 400 ? true : false)
  const updateIsMobile = (m:boolean) => {
    setIsMobile(m)
  }
  const updateIsMobileWide = (w:boolean) => {
    setIsMobileWide(w)
  }
  // const [windowSize, setWindowSize] = useState(getWindowSize())
  useEffect(() => {
    function handleWindowResize() {
      // setWindowSize(getWindowSize())
      if (window.innerWidth <= 768) {
        updateIsMobile(true)
      } else {
        updateIsMobile(false)
      }
      if (window.innerHeight <= 400) {
        updateIsMobileWide(true)
      } else {
        updateIsMobileWide(false)
      }
    }
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  // *********** LOTTIE LOGO ***********
  let timerShowLogo: any
  let lottieLogoContainer = useRef<HTMLInputElement>(null);
  const [logoAnimationComplete, setLogoAnimationComplete] = useState(false)
  const logoAnimationCompleted = () => {
    setLogoAnimationComplete(true)
  }
  let logoAnim: any = useRef(null)
  useEffect(() => {
    if (
      lottieLogoContainer.current !== undefined &&
      lottieLogoContainer.current !== null) {

      logoAnim.current = lottie.loadAnimation({
        container: lottieLogoContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: animationLogo,
      })
      logoAnim.current.addEventListener("complete", logoAnimationCompleted)
    }
    if (!timerShowLogo) {
      timerShowLogo = setTimeout(() => {
        if (logoAnim.current) {
          logoAnim.current.play()
        }
      }, 2000)
    }
    return () => {
      logoAnim.current?.removeEventListener()
      logoAnim.current?.destroy()
      if (timerShowLogo) {
        clearTimeout(timerShowLogo)
      }
    }
  }, [])

  return (
    <section className="w-full h-screen">
      <div className="absolute top-0 z-10 w-full h-screen overflow-hidden">
        <div className=
        {`align-middle w-full px-4 sm:px-6 
        ${ isShortHeight ? 'pt-24 ': 'pt-32 md:pt-40'} 
         pb-12 md:pb-20 
        `}
        >
          <div className="flex flex-col align-middle text-center pb-12 md:pb-16 ">
            <h1
              className=
              {`font-extrabold leading-tighter tracking-tighter mb-4 text-white 
              font-family-opensans              
              ${ !isShortHeight ? 'text-4xl md:text-5xl': ''}               
              ${ isShortHeight ? 'text-2xl md:text-3xl': ''}               
              `}
              data-aos="zoom-y-out"
            >
              Providing Quality Development Services since 2005
            </h1>
            <h2
              className={` 
              font-family-opensans text-white
              
              mb-2
              ${ isShortHeight ? 'text-sm' : 'text-md lg:text-md xl:text-lg'}               
              `}
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              ABC Interactive LLC offers web / app / mobile development, design and SEO <Link to="/services">services</Link>.
            </h2>
            <p
              className={` 
              font-family-opensans text-green-300 font-bold italic uppercase 
              mb-8
              ${ isShortHeight ? 'text-md' : 'text-md lg:text-lg xl:text-lg'}              
              `}
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              We have entered the "Age of Information"
            </p>
          </div>
          <div className="absolute bottom-0 w-full flex items-center justify-center ">
            <a
              // rotate-90 scale-50 duration-500
              // delay-1000 scale-125 duration-2000
              className={`
              margin-bottom-10
              btn text-white  hover:bg-gray-800 font-family-opensans`}
              href="#welcome"
            >
              <button
                className={`
                      transition ease-in-out delay-150 
                      hover:-translate-y-1 hover:scale-110 hover:bg-green-500 duration-300 
                      px-4 py-2 font-medium tracking-wide text-white 
                      uppercase transform bg-blue-600 rounded-md 
                      focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 font-family-opensans`}
              >
                Development Services
              </button>
            </a>
          </div>
        </div>
        <div
          id="lottie-logo"
          className={`
              h-full w-full
              absolute top-0            
              transform z-50 pointer-events-none 
              transition ease-in-out 
              absolute-top-20          
              origin-center 
              inline-flex justify-center            
               ${
                 logoAnimationComplete
                   ? "rotate-90 scale-50 duration-500"
                   : "delay-1000 scale-125 duration-2000"
               }
                `}
        >
          <div
            className={`
               h-screen origin-center  
                 
               ${
                 logoAnimationComplete
                   ? "transform delay-1000 opacity-0 duration-500 margin-left-50"
                   : ""
               }           
              `}
            ref={lottieLogoContainer}
          />
        </div>
      </div>
      <div className="static">
        <StaticImage
          src="../../content/assets/abci_bg.png"
          layout="fullWidth"
          quality={85}
          alt={"abc interactive llc"}
          className="absolute top-0 object-cover object-center w-screen h-screen z-0 max-w-full"
        />
      </div>
    </section>
  )
}

export default HeroHome

function getWindowSize() {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}
